import Swiper, { Navigation, Pagination } from 'swiper';

const swiperTeam = new Swiper('.slider', {
  slidesPerView: 1,
  modules: [Navigation, Pagination],
  loop: true,
  pagination: {
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  draggable: true,
  grabCursor: true,
});
